<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('podcForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-form ref="podcForm" :model="podcForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="原合同编号" prop="podr_no">
            <el-input disabled v-model="podcForm.podr_no" maxlength="20" show-word-limit placeholder="请填写原合同编号"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="修改次数" prop="podc_num">
            <el-input v-model="podcForm.podc_num" maxlength="10" show-word-limit placeholder="请填写修改次数"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商简称" prop="supp_abbr">
            <el-input disabled v-model="podcForm.supp_abbr" maxlength="20" show-word-limit placeholder="请填写供应商简称"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="采购经办人" prop="podr_stff_id">
            <el-select v-model="podcForm.podr_stff_id" placeholder="请选择采购经办人" size="small" clearable filterable>
              <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购所属部门" prop="podr_dept_id">
            <el-select v-model="podcForm.podr_dept_id" size="small" placeholder="请选择采购所属部门" filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="变更类型:" prop="podc_type">
            <el-select size="small" filterable v-model="podcForm.podc_type" placeholder="请选择变更类型">
              <el-option v-for="item in changeTypeList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="外销经办人" prop="cust_stff_id">
            <el-select v-model="podcForm.cust_stff_id" placeholder="请选择外销经办人" size="small" clearable filterable>
              <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="外销所属部门" prop="cust_dept_id">
            <el-select v-model="podcForm.cust_dept_id" size="small" placeholder="请选择外销所属部门" filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="变更日期:" prop="podc_date">
            <el-date-picker v-model="podcForm.podc_date" type="date" placeholder="选择日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="原送货地址" prop="podr_address">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }"
              v-model="podcForm.podr_address"
              maxlength="10"
              show-word-limit
              placeholder="请填写原送货地址"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="新送货地址" prop="new_podr_address">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }"
              v-model="podcForm.new_podr_address"
              maxlength="10"
              show-word-limit
              placeholder="请填写新送货地址"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col>
          <div class="vg_mb_16">
            <el-button type="primary" plain size="small">新增</el-button>
            <el-button type="danger" plain size="small">删除</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <ProdDetail :podcForm="podcForm"></ProdDetail>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col :md="12">
          <el-form-item label="原文" prop="podc_no">
            <el-input v-model="podcForm.podc_no" :autosize="{ minRows: 3, maxRows: 6 }" maxlength="10" placeholder="请填写原文" show-word-limit type="textarea">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item label="现改为" prop="podc_no">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }"
              v-model="podcForm.podc_no"
              maxlength="10"
              show-word-limit
              placeholder="请填写现改为内容"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-timeline>
            <el-timeline-item timestamp="更改后数量" placement="top">
              <el-card>
                <div>
                  <el-row>
                    <el-col :md="10">
                      <el-form-item label="原文" prop="podc_no">
                        <!-- <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6}"  v-model="podcForm.podc_no" maxlength="10" show-word-limit placeholder="请填写原文">
                        </el-input> -->
                        <div>
                          111111111111111111111111111111111111111111111111111111111111111111111111111111111111111
                          1111111111111111111111111111111111111111111111111111111111111111
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :md="14">
                      <el-row>
                        <el-col :md="24">
                          <el-form-item label="现改为" prop="podc_no">
                            <el-input
                              type="textarea"
                              readonly
                              :autosize="{ minRows: 1, maxRows: 6 }"
                              v-model="value11"
                              maxlength="10"
                              show-word-limit
                              placeholder="请填写现改为内容"
                            >
                            </el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row class="vg_pt_8">
                        <el-col :md="24">
                          <el-form-item label="备注" prop="podc_no">
                            <el-input v-model="value11" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请填写现改为内容" type="textarea"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-divider></el-divider>
                </div>
                <div>
                  <el-row>
                    <el-col :md="10">
                      <el-form-item label="原文" prop="podc_no">
                        <!-- <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6}"  v-model="podcForm.podc_no" maxlength="10" show-word-limit placeholder="请填写原文">
                        </el-input> -->
                        <div>
                          111111111111111111111111111111111111111111111111111111111111111111111111111111111111111
                          1111111111111111111111111111111111111111111111111111111111111111
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :md="14">
                      <el-row>
                        <el-col :md="24">
                          <el-form-item label="现改为" prop="podc_no">
                            <el-input
                              type="textarea"
                              readonly
                              :autosize="{ minRows: 1, maxRows: 6 }"
                              v-model="value11"
                              maxlength="10"
                              show-word-limit
                              placeholder="请填写现改为内容"
                            >
                            </el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row class="vg_pt_8">
                        <el-col :md="24">
                          <el-form-item label="备注" prop="podc_no">
                            <el-input v-model="value11" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请填写现改为内容" type="textarea"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-divider></el-divider>
                </div>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="单价" placement="top">
              <el-card>
                <h4>更新 Github 模板</h4>
                <p>王小虎 提交于 2018/4/12 20:46</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { podcAPI } from '@api/modules/podc';
import { deptAPI } from '@api/modules/department';
import { stffAPI } from '@/api/modules/staff';
import helper from '@assets/js/helper';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import ProdDetail from './Component/ProdDetail.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'PodcAddMain',
  components: {
    addHeader,
    inputUser,
    ProdDetail
  },
  data() {
    return {
      rules: {
        podc_type: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      podcForm: {
        podc_no: null,
        podr_no: null,
        podc_num: null,
        supp_abbr: null,
        podc_type: null,
        podc_date: new Date().getTime(),
        podc_remark: null,
        podr_stff_id: null,
        podr_dept_id: null,
        cust_stff_id: null,
        cust_dept_id: null,
        mtrb_name: null,
        podc_item: null,
        prod_part_list: []
      },
      value11: '222222222222222222222<br/>222222222222222',
      stffList: [],
      deptList: [],
      purchaseTypeList: [
        { id: 1, label: '成品采购' },
        { id: 2, label: '委托加工' }
      ], //采购类型
      changeTypeList: [
        { id: 1, label: '取消商品' },
        { id: 2, label: '单价数量' },
        { id: 3, label: '交期' },
        { id: 4, label: '货号数量' },
        { id: 5, label: '其他' }
      ] //变更类型
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getDept();
      this.getStffUser();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let podcForm = JSON.parse(JSON.stringify(this.podcForm));
      podcForm = Object.assign(podcForm, staffForm);
      post(podcAPI.addPodc, podcForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump('/podc_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });

            this.resetForm('podcForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('podcForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 获取部门
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
</style>
