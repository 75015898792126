import topic from '../topic';
export const podcAPI = {
  getPodcs: topic.podcTopic + '/get_podcs',
  getPodcById: topic.podcTopic + '/get_podc_by_id',
  addPodc: topic.podcTopic + '/add_podc',
  editPodc: topic.podcTopic + '/edit_podc',
  deletePodcByIds: topic.podcTopic + '/delete_podc_by_ids',
  exportPodcPdfById: topic.podcTopic + '/pdf/export_podc_pdf_by_id',
  getPodcRecord: topic.podcTopic + '/getPodcRecord'
};
